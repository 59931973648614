var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CommonTableView', {
    attrs: {
      "headers": _vm.constants.TABLE_HEADERS,
      "items": _vm.state.exportFiles,
      "loading": _vm.state.isLoading,
      "with-info": false,
      "with-download": "",
      "options": _vm.state.vuetifyTableOptions
    },
    on: {
      "update:options": [function ($event) {
        return _vm.$set(_vm.state, "vuetifyTableOptions", $event);
      }, function ($event) {
        return _vm.functions.debouncedCb();
      }],
      "click:download": function clickDownload($event) {
        return _vm.listeners.onClickDownload($event);
      },
      "click:delete": function clickDelete($event) {
        return _vm.listeners.onExportFileDelete($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "entity",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.$t("administration.exportFile.".concat(item.entity, ".title"))) + " ")];
      }
    }, {
      key: "item.actions.files",
      fn: function fn() {
        return [!_vm.functions.hasSufficientRights(_vm.constants.Rights.EXPORT_FILE_DOWNLOAD) ? _c('div') : _vm._e()];
      },
      proxy: true
    }, {
      key: "item.actions.delete",
      fn: function fn() {
        return [!_vm.functions.hasSufficientRights(_vm.constants.Rights.EXPORT_FILE_DELETE) ? _c('div') : _vm._e()];
      },
      proxy: true
    }], null, true)
  }, [_c('CommonDeleteDialog', {
    on: {
      "cancel": function cancel($event) {
        _vm.state.isDeleteModalOpen = false;
      },
      "delete": function _delete($event) {
        return _vm.functions.deleteExportFile();
      }
    },
    model: {
      value: _vm.state.isDeleteModalOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isDeleteModalOpen", $$v);
      },
      expression: "state.isDeleteModalOpen"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('administration.exportFile.deleteExportFile.text')) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }