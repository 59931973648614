function downloadHandler(data: Blob, filename: string): void {
  const fileURL = window.URL.createObjectURL(data)
  const fileLink = document.createElement('a')

  fileLink.href = fileURL
  fileLink.setAttribute('download', `${filename}`)

  document.body.appendChild(fileLink)

  fileLink.click()
  fileLink.remove()
}

export { downloadHandler }
