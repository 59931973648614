
import { defineComponent, reactive, ref } from '@vue/composition-api'
import { cloneDeep } from 'lodash-es'

import CommonTableView from '@/components/common/CommonTableView.vue'

import { useNotify } from '@/store'

import { useFilter } from '@/composables/useFilter'

import { setSort } from '@/utils/manipulateTableSort'

import { hasSufficientRights } from '@/utils/hasSufficientRights'
import { Rights } from '@/api/types/right'
import { useDeleteExportFile, useDownloadExportFile, useGetExportFiles } from '@/api/exportFile'
import { ExportFileOutput } from '@/api/types/exportFile'

export default defineComponent({
  name: 'ExportFiles',
  components: {
    CommonTableView,
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
  },
  setup: (_, { root }) => {
    const TABLE_HEADERS = [
      {
        text: root.$t('administration.exportFiles.table.col.title.entity'),
        value: 'entity',
        sortable: true,
      },
      {
        text: root.$t('administration.exportFiles.table.col.title.title'),
        value: 'title',
        sortable: true,
      },
      {
        text: root.$t('administration.exportFiles.table.col.title.createdAt'),
        value: 'createdAt',
        sortable: true,
      },
      {
        text: root.$t('administration.exportFiles.table.col.title.actions'),
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const { addNotification } = useNotify()

    const { debouncedCb, vuetifyTableOptions, paginationParams } = useFilter([], init)
    setSort(vuetifyTableOptions, { by: 'createdAt', desc: true })

    const { exec: getExportFiles, data: exportFiles, isLoading } = useGetExportFiles()

    async function init(): Promise<void> {
      await getExportFiles({ params: { ...paginationParams.value } })
    }

    const isDeleteModalOpen = ref(false)
    const exportFileToDelete = ref<null | ExportFileOutput>(null)

    const { deleteExportFile: deleteExportFileXhr } = useDeleteExportFile()

    async function deleteExportFile(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await deleteExportFileXhr(exportFileToDelete.value!.id)

      addNotification({
        text: root.$t('administration.exportFiles.delete.message') as string,
        type: 'success',
      })

      isDeleteModalOpen.value = false

      init()
    }

    function onExportFileDelete(exportFile: ExportFileOutput): void {
      exportFileToDelete.value = cloneDeep(exportFile)

      isDeleteModalOpen.value = true
    }

    const { downloadExportFile } = useDownloadExportFile()

    function onClickDownload(exportFile: ExportFileOutput): void {
      downloadExportFile(exportFile.id)
    }

    return reactive({
      constants: {
        Rights,

        TABLE_HEADERS,
      },
      state: {
        exportFiles,
        isLoading,

        isDeleteModalOpen,

        vuetifyTableOptions,
      },
      functions: {
        deleteExportFile,

        debouncedCb,

        hasSufficientRights,
      },
      listeners: {
        onClickDownload,
        onExportFileDelete,
      },
    })
  },
})
