import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import { PromiseType } from '@/utils/types/PromiseType'
import {
  ExportFileId,
  ExportFileOutput,
  UseDeleteExportFile,
  UseDownloadExportFile,
  UseGetExportFile,
  UseGetExportFiles,
} from './types/exportFile'
import { downloadHandler } from './utils/downloadHandler'

const VERSION = 'v1'
const RESOURCE = 'export-files'

function useGetExportFiles(): UseGetExportFiles {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetExportFiles['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
  })
  return axios
}

function useGetExportFile(): UseGetExportFile {
  const axios = useAxios<PromiseType<ReturnType<UseGetExportFile['getExportFile']>>>({ method: 'GET' })

  function getExportFile(id: ExportFileId): Promise<ExportFileOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    getExportFile,
  }
}

function useDownloadExportFile(): UseDownloadExportFile {
  const axios = useAxios<Blob>({ method: 'GET' })

  const { getExportFile } = useGetExportFile()

  async function downloadExportFile(id: ExportFileId): Promise<void> {
    const axiosResponse = await axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}/file`, responseType: 'blob' })
    const fileMetadata = await getExportFile(id)

    downloadHandler(axiosResponse, fileMetadata.title)
  }

  return {
    ...axios,
    downloadExportFile,
  }
}

function useDeleteExportFile(): UseDeleteExportFile {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteExportFile['deleteExportFile']>>>({ method: 'DELETE' })

  function deleteExportFile(id: ExportFileId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteExportFile,
  }
}

export { useGetExportFiles, useGetExportFile, useDownloadExportFile, useDeleteExportFile }
